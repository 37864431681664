import React from "react"

import Jaime from "../components/jaime"
import SEO from "../components/seo"
import OGImage from "../images/jaime.jpg"

import "./reset.less"
import "./styles.less"

const IndexPage = () => {
  return (
    <div className="wrapper">
      <main>
        <SEO
          title="Jaime Aoyagi"
          description="I’m the Founder and CEO of Worksimply, a hybrid workplace platform that reduces office space costs and increases employees’ freedom."
          image={OGImage}
        />

        <div className="inner">
          <div className="portrait">
            <Jaime />
          </div>
          <h1>Jaime Aoyagi</h1>
          <h2>
            Founder @{" "}
            <a
              href="https://worksimply.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Worksimply
            </a>{" "}
          </h2>
          <p>
            I’m the Founder and CEO of Worksimply, a hybrid workplace platform that reduces office space costs and increases employees’ freedom.
          </p>

          <ul>
            <li>
              <a
                href="https://ca.linkedin.com/in/jaimeaoyagi"
                rel="noopener noreferrer"
                target="_blank"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/JaimeAoyagi "
                rel="noopener noreferrer"
                target="_blank"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="mailto:jaime.aoyagi@gmail.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                Email
              </a>
            </li>
          </ul>
        </div>
      </main>
      <footer>
        <p>&copy; {new Date().getFullYear()} Jaime Aoyagi</p>
      </footer>
    </div>
  )
}

export default IndexPage
